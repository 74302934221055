/** @jsx jsx */
import { jsx, Container, Flex, Box, Styled, Button, Input } from "theme-ui"
import Layout from "../components/layout"
import {Link} from "gatsby"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Box sx={{p: 5, backgroundColor: "dark"}}>
      <Container sx={{width: "100%", maxWidth: "max", height: "100vh"}}>
        <Flex sx={{flexWrap: "wrap", alignItems: "center", color: "light", height: "100vh"}}>
          <div sx={{width: ["100%", "100%", "60%"]}}>
            <Styled.h1 sx={{color: "light"}}>Create a Healthy Lifestyle One Habit at a Time.</Styled.h1>
            <Styled.h2 sx={{color: "light"}}>Welcome to your new Gatsby site.</Styled.h2>
            <Flex sx={{mt: 6, backgroundColor: "light", width: ["100%","100%","50%"]}}>
              <input placeholder="Email" sx={{border: "none", width: "70%", pl: 5}} />
              <Button as={Link} sx={{width: "30%"}}>Join the list</Button>
            </Flex>
          </div>
          <div sx={{width: ["100%", "100%", "40%"],px: 5}}>
            This is an image
          </div>
        </Flex>
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
